import React from 'react'
import Header from '../components/Header'
import clx from './Home.module.sass'
import Container from '@mui/material/Container';
import { Button, Grid } from '@mui/material';
import ReactPlayer from 'react-player'
import {
    Link
} from "react-router-dom";

import appstoreicon from '../assets/app-store.svg';
import star from '../assets/Star.svg';
import basketball from '../assets/basketball-removebg-preview.png'
import practice_mode from '../assets/practice_mode.png'
import practice_mode2 from '../assets/practice_mode2.png'
import practice_mode3 from '../assets/practice_mode3.png'
import practice_mode4 from '../assets/practice_mode4.png'
import tap_icon from '../assets/icons/tap.svg'
import smarthome_icon from '../assets/icons/smartphone.svg'
import ball_icon from '../assets/icons/basketball-ball-variant.svg'
import bars_icon from '../assets/icons/bars.svg'
import star_icon from '../assets/icons/star.svg'
import ranking_icon from '../assets/icons/ranking.svg'
import video_icon from '../assets/icons/video.svg'
import userImage02 from '../assets/UserImage02.png';
import userImage04 from '../assets/UserImage04.png';
import userImage05 from '../assets/UserImage05.png';
import userImage06 from '../assets/UserImage06.png';
import userImage13 from '../assets/UserImage13.png';
import userImage14 from '../assets/UserImage14.png';
import userImage19 from '../assets/UserImage19.png';
import userImage01 from '../assets/UserImage01.png';
import userImage12 from '../assets/UserImage12.png';
import video1 from '../assets/videos/home_first.mp4'
import video1og from '../assets/videos/home_first.ogg'
import playIcon from '../assets/videos/play_icon.svg'
import parent_commertial_ph from '../assets/videos/placeholder.png'
import parent_commertial from '../assets/videos/parent_commertial.mp4'
import parent_commertial_ogg from '../assets/videos/parent_commertial.ogg'


export default function Home() {

    const handleButtonClick = () => {
        window.open('https://apps.apple.com/us/app/elete-dribble/id1454189239?ls=1', '_blank', 'noopener,noreferrer');
    }
    return (
        <React.Fragment>
            <div className={clx.section_bg}>
                <Header />
                <Container sx={{ marginTop: '145px', marginBottom: '145px', '@media screen and (max-width: 900px)': { marginBottom: '40px' }, }} >
                    <Grid container spacing={4}>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                            <h2 className={clx.title}>Take Your Game<br /> To <span>The Next Level.</span> </h2>
                            <p className={clx.info_text}>Elete Basketball training app transforms your game to the elite level. It tracks your performance in real-time and provides instant feedback on how to improve. It’s like having your own personal coach in your pocket, no matter where you are.</p>
                            <Button className={clx.primary_button} onClick={handleButtonClick}>DOWNLOAD now <br /><span> and start training like a pro</span></Button>
                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                            <div className={clx.video_player_wrapper} style={{ borderRadius: '10px', filter: 'drop-shadow(15px 15px 45px rgba(0, 0, 0, 0.35))' }}>
                                <ReactPlayer
                                    className={clx.react_player}
                                    playing={true}
                                    url={[
                                        { src: video1, type: 'video/mp4' },
                                        { src: video1og, type: 'video/ogg' }
                                    ]}
                                    controls={true}
                                    playIcon={<img src={playIcon} alt="play" />}
                                    // light={video_placeholder}
                                    style={{ maxWidth: '100%' }}
                                    muted={true}

                                />
                            </div>
                        </Grid>
                    </Grid>
                </Container>
                <Container >
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <div className={clx.appstore_rate}>
                                <img src={appstoreicon} alt="asi" />
                                <div className={clx.appstore_rate_num}>
                                    <span className={clx.rate}>4.6</span>
                                    <span className={clx.stars}>
                                        <img src={star} alt="asi" />
                                        <img src={star} alt="asi" />
                                        <img src={star} alt="asi" />
                                        <img src={star} alt="asi" />
                                        <img src={star} alt="asi" />
                                    </span>
                                    <p>Join thousands of satisfied users</p>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>

            <div className={clx.section_app_info} >
                <Container >
                    <Grid container spacing={0}  >
                        <Grid item xl={5} md={5} sm={12} xs={12} sx={{ marginTop: '40px', display: { xs: 'none', md: 'block' } }}>
                            <img src={basketball} alt="basketball" />
                        </Grid>
                        <Grid item xl={7} md={7} sm={12} xs={12} sx={{ marginTop: '40px' }}>
                            <h2 className={clx.title}>Become <span>an unstoppable force</span> on the court.</h2>
                            <p className={clx.info_text}>Every great player has one thing in common, an unstoppable drive to improve their game. The key to becoming the best on the court is consistently practicing high-quality skills. With Elete's instant feedback, immersive drills, and ability to compete with players around the world, you’ll get better faster than ever before. </p>
                            <Button className={clx.primary_button} onClick={handleButtonClick}>DOWNLOAD the app now<span> and start improving your game Today!</span></Button>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={clx.section_improve}>
                <Container>
                    <div className={clx.block_right}>
                        <Grid container spacing={0}  >
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <div className={clx.block_right_info}>
                                    <h4>Train Smarter, Not Harder!</h4>
                                    <h2 className={clx.title}>Turn Your Weakness Into Your Strengths!</h2>
                                    <p className={clx.info_text}>We do this by capturing all of your speed, movement, and skill level. Real-time feedback and detailed analytics give you the knowledge to know exactly what you need to do to improve your game and actually see the progress in your game. It is analysis that makes players better!</p>
                                    <Button className={clx.link_button} onClick={handleButtonClick}>Start improving your game today</Button>
                                </div>

                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <img src={practice_mode} alt="basketball" className={clx.block_right_img} />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={clx.section_blocks}>
                <Container>
                    <div className={clx.block_right}>
                        <Grid container spacing={0}  >
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <img src={practice_mode2} alt="basketball" className={clx.block_left_img} />
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <div className={clx.block_right_info}>
                                    <h4>Training Is Fun Again!</h4>
                                    <h2 className={clx.title}>No more boring drills and workouts!</h2>
                                    <p className={clx.info_text}>Traditional drills and workouts are boring, and many players find it hard to stick to them. Elete's immersive drills and workouts make practicing your skills more fun and engaging, so you'll be more likely to stick with it and improve your skills.</p>
                                    <Button className={clx.link_button} onClick={handleButtonClick}>make your training sessions fun</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                    <div className={clx.block_right}>
                        <Grid container spacing={0}  >
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <div className={clx.block_right_info}>
                                    <h4>From beginners to pros.</h4>
                                    <h2 className={clx.title}>Interactive Drills for Every Skill Level</h2>
                                    <p className={clx.info_text}>It’s for players of all levels and ages. Created by professional coaches, it offers over 1,000+ high-quality drills that can be accessed anytime, anywhere.</p>
                                    <Button className={clx.link_button} onClick={handleButtonClick}>Start progressing faster than ever</Button>

                                </div>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <img src={practice_mode3} alt="basketball" className={clx.block_right_img} />
                            </Grid>
                        </Grid>
                    </div>
                    <div className={clx.block_right} style={{ marginBottom: '0' }}>
                        <Grid container spacing={0}  >
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <img src={practice_mode4} alt="basketball" className={clx.block_left_img} />
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} >
                                <div className={clx.block_right_info}>
                                    <h4>Virtual Competitions</h4>
                                    <h2 className={clx.title}>The world will know your name!</h2>
                                    <p className={clx.info_text}>Playing against others is the best way to improve. You need a competitive environment to get better. By providing a challenging and fun experience, Elete prepares you to compete with the best basketball players in the world, giving you the skills you need to dominate the court. </p>
                                    <Button className={clx.link_button} onClick={handleButtonClick}>Challenge them to a match today!</Button>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
            <div className={clx.section_train}>
                <Container sx={{ textAlign: 'center' }}>
                    <Grid container spacing={0} justifyContent="center" >
                        <Grid item xl={10} md={10} sm={12} xs={12} >
                            <h1 className={clx.title}>Train Anywhere, Anytime.</h1>
                            <p className={clx.sub_title}>You don't have time to go to the gym? No worries, with Elete you can train any time, any place. All you need is a few feet of space, a basketball, and an iPhone or iPad.</p>
                            <Button className={clx.primary_button} onClick={handleButtonClick}>DOWNLOAD The App now <br /><span> and start training on any court, anytime!</span></Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} justifyContent="center" >
                        <Grid item xl={10} md={10} sm={12} xs={12} >
                            <h3 className={clx.title_3}>PREPARE YOUR WORKOUT IN SECONDS.</h3>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} justifyContent="center" >
                        <Grid item xl={4} md={4} sm={4} xs={12} >
                            <div className={clx.card}>
                                <img src={tap_icon} alt="icon" />
                                <h4 className={clx.card_title}>Choose To Train or Compete</h4>
                                <p className={clx.card_info}>Train to beat your high scores or compete against the Elete community.</p>
                            </div>
                        </Grid>
                        <Grid item xl={4} md={4} sm={4} xs={12} >
                            <div className={clx.card}>
                                <img src={smarthome_icon} alt="icon" />
                                <h4 className={clx.card_title}>Set Device On Ground</h4>
                                <p className={clx.card_info}>Lean your device against a water bottle at the correct angle by following the instructions.</p>
                            </div>
                        </Grid>
                        <Grid item xl={4} md={4} sm={4} xs={12} >
                            <div className={clx.card}>
                                <img src={ball_icon} alt="icon" />
                                <h4 className={clx.card_title}>Ready To Master Your Skills</h4>
                                <p className={clx.card_info}>Learn skills needed to take defenders off the dribble and be the ultimate player on the court.</p>
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={clx.section_promo}>
                <Container >
                    <Grid container spacing={4} alignItems="flex-end">
                        <Grid item xl={6} md={6} sm={12} xs={12} sx={{ display: { xs: 'block', md: 'none' } }} >
                            <h1 className={clx.title}>Help Your Children Improve Their Basketball Skills.</h1>
                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12} className={clx.video_player_wrapper}>
                            <ReactPlayer
                                className={clx.react_player}
                                style={{ maxWidth: '100%' }}
                                playing={true}
                                url={[
                                    { src: parent_commertial, type: 'video/mp4' },
                                    { src: parent_commertial_ogg, type: 'video/ogg' }
                                ]}
                                controls={true}
                                playIcon={<img src={playIcon} alt="play" />}
                                light={parent_commertial_ph}
                            />
                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12} sx={{ display: { xs: 'none', md: 'block' } }} >
                            <h1 className={clx.title}>Help Your Children Improve Their Basketball Skills.</h1>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            <div className={clx.section_exp}>
                <Container >
                    <Grid container spacing={4} >
                        <Grid item xl={6} md={6} sm={12} xs={12} >
                            <h1 className={clx.title}>A personalized experience to <span>improve your game.</span></h1>
                            <h3 className={clx.sub_title}>Unlock your potential with Elete.</h3>
                            <ul>
                                <li>
                                    <img src={bars_icon} alt="icon" />
                                    <div>
                                        <h5>ALL LEVELS</h5>
                                        <p>For Players, Coaches, and Trainers of Any Level.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src={star_icon} alt="icon" />
                                    <div>
                                        <h5>CLIMB THE RANKS</h5>
                                        <p>Start in High School, journey to the pros.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src={ranking_icon} alt="icon" />
                                    <div>
                                        <h5>TRACKING</h5>
                                        <p>Keep up with all drills that are completed to help follow a path to success.</p>
                                    </div>
                                </li>
                                <li>
                                    <img src={video_icon} alt="icon" />
                                    <div>
                                        <h5>1,000+ HD VIDEO WORKOUTS</h5>
                                        <p>All videos play easily inside the app on wifi or phone network.</p>
                                    </div>
                                </li>
                            </ul>
                            <Button className={clx.primary_button} onClick={handleButtonClick}>DOWNLOAD now <br /><span>Available for iPhone & iPad</span></Button>

                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12} className={clx.images_block}>
                            <Grid container spacing={4} >
                                <Grid item xl={6} md={6} sm={6} xs={6} >
                                    <img src={userImage02} alt="" />
                                    <img src={userImage19} alt="" />
                                    <img src={userImage04} alt="" />
                                    <img src={userImage05} alt="" />
                                    <img src={userImage06} alt="" />

                                </Grid>
                                <Grid item xl={6} md={6} sm={6} xs={6} sx={{ marginTop: '90px' }} >
                                    <img src={userImage01} alt="" />
                                    <img src={userImage12} alt="" />
                                    <img src={userImage13} alt="" />
                                    <img src={userImage14} alt="" />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
                {/* <div className={clx.footer_line}>
                    <img src={line3} alt="" />
                </div> */}
                <div className={clx.orang_bdy_sec_wrapper}></div>
            </div>
            <div className={clx.footer}>
                <Container >
                    <Grid container spacing={0} justifyContent="center" sx={{ textAlign: 'center' }} >
                        <Grid item xl={6} md={8} sm={12} xs={12} >
                            <h1 className={clx.title}>Become the next
                                Basketball Superstar!</h1>
                        </Grid>
                        <Grid item xl={8} md={10} sm={12} xs={12} >
                            <p className={clx.sub_title}>We want to help you achieve your dream of becoming a professional basketball player. With our easy-to-use platform and high-quality drills, you’ll be able to improve your skills in no time. Greatness awaits!</p>
                            <Button className={clx.primary_button} onClick={handleButtonClick}>DOWNLOAD now <br /><span>Available for iPhone & iPad</span></Button>
                        </Grid>
                    </Grid>
                </Container>
                <div className={clx.sub_footer}>

                    <Container >
                        <Grid container spacing={0} justifyContent="center" alignItems="center" >
                            <Grid item xl={10} md={12} sm={12} xs={12} >
                                <Grid container spacing={0} justifyContent="space-between" alignItems="center" sx={{ justifyContent: { xs: 'center' } }}>
                                    <Grid item xl={6} md={6} sm={6} xs={12} >
                                        <h6>Elete Basketball © All Rights Reserved.</h6>
                                    </Grid>
                                    <Grid item xl={6} md={6} sm={6} xs={12} >
                                        <ul>
                                            <li> <Link to="/privacy">Privacy Policy</Link></li>
                                            {/* <li>Disclaimer</li> */}
                                            <li><Link to="/terms-conditions">Terms & Conditions</Link></li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>

        </React.Fragment>
    )
};
