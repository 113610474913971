import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Container from '@mui/material/Container';
import logo from '../assets/logo.svg'
import clx from './Header.module.sass'

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 0 : 0,
    // style:{
    //   backgroundColor: trigger ? '#000': 'transparent'
    // },
  });
}

export default function Header(props) {
  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <ElevationScroll {...props}>
        <AppBar position="relative" sx={{ backgroundColor: 'transparent'}}>
          <Container>
            <Toolbar className={clx.toolbar} >
              <img src={logo} alt="logo" />
              <a className={clx.orange_button} target="_blank" rel="noreferrer" href="https://apps.apple.com/us/app/elete-dribble/id1454189239?ls=1">Download app</a>
            </Toolbar>
          </Container>
        </AppBar>
      </ElevationScroll>
    </React.Fragment>
  )

};
